

export default {
  name: 'HomePageFaq',

  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      open: [],
    }
  },

  computed: {
    getHeader () {
      return this.api.data.header.text || null
    },

    getItems () {
      return this.api.data.faqItems || null
    },
  },

  methods: {
    toggle (index) {
      this.open = this.open.includes(index)
        ? this.open.filter(el => el !== index)
        : [...this.open, index]
    },
  },
}
